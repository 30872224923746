@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/_spacing.scss';
@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';

$category-link-spacing: 8px;

.categoryLinks {
  margin: $ingredients-unit #{$category-link-spacing * -1} 0;
}

.item {
  flex: 1 0 100%;
  margin: $category-link-spacing;

  // max-width set to ensure text-overflow:ellipsis style works
  max-width: calc(100% - #{$category-link-spacing * 2});

  @include media-breakpoint-up('md') {
    flex: 0 0 auto;
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  font-size: $font-size-base;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.breakpointHeights {
  $row-height: 56px;
  max-height: ($row-height * 4) + ($row-height * 0.5);

  @include media-breakpoint-up('md') {
    max-height: ($row-height * 2) + ($row-height * 0.5);
  }
}

.themeRed {
  &,
  &:focus {
    color: $colour-white;
  }
}

.themeWhite {
  background-color: $colour-white;
  padding: $ingredients-gutter;
  margin-bottom: $grid-vertical-gutter;
}
