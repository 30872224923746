@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../styles/tools/extends/carousel';

div.chosenForYou {
  @extend %with-top-and-bottom-dividers;
}

.browseHeader {
  background: {
    color: $colour-scallop-grey;
    repeat: repeat;
  }
}

.seoContent {
  background-color: $colour-eggshell-grey;
  padding-top: 16px;
}

.recommendationsWrapper {
  background-color: $colour-scallop-grey;
}

.removeMarginInMobile {
  @media (max-width: 375px) {
    padding: 0;
  }
}

.headerContent {
  padding-bottom: 20px;
}
