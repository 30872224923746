@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../styles/settings/variables';
@import '../mixins/typography';

.sectionTitle {
  margin-bottom: $grid-vertical-gutter;
}

.title,
.titleWithoutThroughLine {
  @include title-h3('light');
  margin: $grid-vertical-gutter 0;
  color: $text-color;
}

.titleWithoutThroughLine {
  text-align: center;
}

%highlight {
  @include title-h3;
  margin: 0 .2em;
  text-transform: uppercase;
}

.highlight {
  @extend %highlight;
}

.offerHighlight {
  @extend %highlight;
  color: $colour-offer-red;
}
