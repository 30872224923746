@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/_spacing.scss';
@import '../../../../styles/settings/variables';
@import '../../mixins/typography';

.productGroup {
  display: flex;
  flex: {
    direction: column;
  }
  width: 100%;
}

.message {
  @include paragraph-sub('bold');

  display: block;
  padding: ($ingredients-unit * 1.75) 0;
  text-align: center;
  width: 100%;
}

.conflict {
  background: $colour-waitrose-cornfield;
}

