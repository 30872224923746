@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../styles/settings/variables';

// WDX Styleguide Variables
// --------------------------------------------------
// TODO [BM]: These should be implemented as part of WDX Button work
$button-secondary-active-background: $text-color;
$button-secondary-active-text-colour: $colour-white;

$button-offer-active-background: $colour-offer-red;
$button-offer-active-text-colour: $colour-white;

$button-active-disabled-background: $colour-chia-grey;
$button-active-disabled-text-colour: $colour-mushroom-grey;

$button-border-radius: 0;
