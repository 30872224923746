@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../ingredients/styles/focus';
@import '../../../styles/settings/variables';
@import '../../Button/constants';

$category-link-horizontal-padding: 20px;

@mixin link-button ($primary, $secondary, $hover) {
  @include focus;
  border-color: $primary;
  color: $primary;

  &:focus {
    color: $primary;
  }

  &:hover {
    border-width: 2px;
    color: $primary;
    padding: 0 ($category-link-horizontal-padding - 1);
  }

  @media (hover: none), (hover: on-demand) {
    // Prevent sticky-hover on devices that don't support hover fully
    // http://www.javascriptkit.com/dhtmltutors/sticky-hover-issue-solutions.shtml
    &:hover {
      background-color: inherit;
      color: $primary;
    }

    &:active {
      background-color: $primary;
      color: $secondary;
    }
  }
}

.label {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

%link {
  align-items: center;
  border: {
    radius: $button-border-radius;
    style: solid;
    width: 1px;
  }
  display: flex;
  height: 40px;
  justify-content: center;
  padding: 0 $category-link-horizontal-padding;
}

.link {
  @extend %link;
}

.favouritesLink {
  @extend %link;
  font-weight: 400;

  &::before {
    content: '\E903';
    font: {
      family: WaitroseGlyph;
    }
    margin: -2px 2px 0 -6px;
  }
}

.myWaitroseLink {
  @extend %link;

  &::before {
    content: 'my';
    font-weight: 200;
  }

  span {
    overflow: hidden;
    width: 0;
  }

  &::after {
    content: 'Waitrose';
    font-weight: 400;
  }
}

.offersLink {
  @extend %link;
  text-transform: uppercase;
}

.themeRed {
  @include link-button($colour-white, $colour-offer-red, $colour-offer-red);
}

.themeGrey,
.themeWhite {
  @include link-button($text-color, $colour-white, $colour-oyster-grey);
}

.redHighlight {
  @include link-button($colour-offer-red, $colour-white, $colour-offer-red);
}
