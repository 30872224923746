@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../styles/settings/variables';
@import '../../styles/tools/mixins';

.browseHeader {
  background: {
    color: $colour-scallop-grey;
    repeat: repeat;
  }
  min-height: 123px;

  @include media-breakpoint-up('md') {
    min-height: 132px;
  }

  .headerImageContainer{
    position: relative; 

    .headerImage{
      height: 145px; 
      object-fit: cover; 
      width:100vw;
      margin-left: -16px; 
      max-width: 1260px !important;

      @include media-breakpoint-up('md') {
        height: 272px;
      }

      @include media-breakpoint-up('lg') {
        margin-left: -32px; 
      }
      
    }

    .headerImageOverlay {
      // stylelint-disable-next-line @johnlewispartnership/eslint-plugin-wtr-ingredients/no-colour-literals
      background-image: radial-gradient(rgb(0 0 0 / 25%), rgb(0 0 0 / 0%));
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
      left: 0;
    }

    .headerImageTitle{
      position: absolute;

      @include media-breakpoint-up('lg') {
        top: calc(50% - 54px);
      }

      @include media-breakpoint-up('md') {
        font-size: 40px; 
      }
    
      top: 50%; 
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 20px; 
      text-align: center; 
      color: white; 
      width: 100%;
    }
  }
}


