@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';

.wrapper {
  background-color: $colour-scallop-grey;
  padding-top: 10px;

  @include media-breakpoint-up('md') {
    padding-top: 20px;
  }
}

.refinements {
  composes: container-fluid from global;
  position: relative;
  min-height: 48px;
  z-index: 2;
}

.wrapperNew {
  background-color: $colour-scallop-grey;
}

.refinements {
  composes: container-fluid from global;
  position: relative;
  background-color: $colour-scallop-grey;
  display: flex;
  align-items: flex-start;
  padding-top: 8px;
  padding-bottom: 8px;
  min-height: 56px;
  z-index: 2;

  @include media-breakpoint-up('md') {
    padding-top: 12px;
    min-height: 64px;
  }

  @include media-breakpoint-up('lg') {
    padding-top: 20px;
    min-height: 80px;
  }
}


.hiddenStickyTooltipPlaceholder {
  visibility: hidden;
  position: fixed;
  height: 56px;
  width: 100%;
  top: 51px;
  left: 0;
  pointer-events: none;

  @include media-breakpoint-up('sm') {
    margin: 0 16px;
  }

  @include media-breakpoint-up('lg') {
    margin: 0 32px;
  }

  @include media-breakpoint-up('xl') {
    margin: 0 calc(32px + (100% - 1260px)/2) ;
  }
}

.stickyRefinements {
  background-color: $colour-scallop-grey;
  box-shadow: 0 0 20px $colour-chia-grey;
  display: flex;
  padding: 16px;
  position: fixed;
  top: -72px;
  transition: all .3s ease-in-out;
  visibility: hidden;
  width: 100%;
  z-index: ($zindex-pod-overlay + 1);

  @include media-breakpoint-up('sm') {
    padding: 12px 16px;
  }

  @include media-breakpoint-up('lg') {
    padding: 16px 32px 0;
    min-height: 72px;
  }

  @include media-breakpoint-up('xl') {
    padding: 16px calc(32px + (100% - map-get($grid-breakpoints, 'xl')) / 2) 0;
  }
}

.stickyEnabled {
  top: 51px;
  visibility: visible;
}
