@import '../../styles/settings/variables';
@import '../../styles/tools/mixins';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/_spacing.scss';

// TODO: Currently this is being overridden in the 'Shop from previous' page due to the floats applied.
// This will need refactoring to remove all the 'col-x' classes.
// https://johnlewispartnership.atlassian.net/browse/WPIP-65434
.addAllItemsButton {
  margin-top: $ingredients-unit * 5;
  margin-bottom: $ingredients-unit * 5;
}
