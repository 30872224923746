@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/_spacing.scss';
@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';

%panel {
  flex: {
    grow: 0;
    shrink: 0;
  }
  text-align: center;
  width: 100%;
}

// [BM] - the 'height' values have been maintained due to previous implementation
.panels {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 200px;
  margin: 0 ($ingredients-gutter * -1);

  @include media-breakpoint-up('sm') {
    height: 270px;
  }

  @include media-breakpoint-up('md') {
    margin: 0 ($ingredients-gutter * -2);
    height: 380px;
  }
}

.scrollablePanel {
  @extend %panel;
  border-bottom: 1px solid $colour-mushroom-grey;
  flex: {
    grow: 1;
    shrink: 1;
  }
  overflow: auto;
  width: 100%;
}
