@import '@johnlewispartnership/wtr-ingredients/foundations/colours';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/_spacing.scss';
@import '../../../ingredients/styles/focus';
@import '../../../styles/tools/mixins';
@import '../../../styles/settings/variables';

$see-more-animation-duration: .4s;
$see-more-fade-height: 20px; // must be half of the button row height
$see-more-fade-opacity-grey-white: .8;
$see-more-fade-opacity-red: .7;
$see-more-flip-duration: .3s;
$see-more-opacity-duration: $see-more-animation-duration * 0.5;

.animated {
  .content {
    transition: max-height $see-more-animation-duration ease;
  }

  .expandButton {
    &::after {
      transition: transform $see-more-animation-duration ease-in-out $see-more-animation-duration;
    }
  }
}

.button {
  align-items: center;
  display: flex;
  justify-content: center;

  .themeGrey &,
  .themeWhite & {
    border-top: 1px solid $colour-primary-grey--alpha-40;
  }

  .themeRed & {
    border-top: 1px solid $colour-white--alpha-50;
  }

}

.content {
  display: block;
  overflow: hidden;
  padding-bottom: 12px; // must be the fade height minus row bottom margin
  position: relative;
}

.cta {
  // Reserve the space on page so expander can be shown
  // if needed without causing any layout shift (CLS)
  height: 45px;
  margin-bottom: 20px;
  padding: {
    left: $ingredients-unit * 2;
    right: $ingredients-unit * 2;
  }
  position: relative;
  visibility: hidden;
}

.expandButton {
  @include focus;
  display: flex;
  color: inherit;
  flex-grow: 0;
  font-size: $font-size-base;
  margin: 10px 0 -6px;
  padding: 0 0 0 10px;

  &,
  &:focus,
  &:hover,
  &:active {
    color: inherit;
    text-decoration: none;
  }
}

.fader {
  bottom: 0;
  height: $see-more-fade-height;
  left: 0;
  position: absolute;
  right: 0;
  transition: opacity $see-more-opacity-duration ease-out $see-more-animation-duration;
  visibility: hidden;
  width: 100%;

  // the fade effect is achieved by overlaying a semi-transparent element of the
  // same background colour to the element below, hence the bg colour must be known
  .themeGrey & {
    opacity: $see-more-fade-opacity-grey-white;
    background-color: $colour-scallop-grey;
  }

  .themeRed & {
    opacity: $see-more-fade-opacity-red;
    background-color: $colour-offer-red;
  }

  .themeWhite & {
    opacity: $see-more-fade-opacity-grey-white;
    background-color: $colour-white;
  }

  .expanded & {
    opacity: 0;
    pointer-events: none;
    transition: opacity $see-more-opacity-duration ease-out;
  }

  .loading & {
    opacity: 1;
    pointer-events: none;
    visibility: visible;
  }
}

.icon {
  // for specificity
    display: flex;
    margin-left: 8px;
    margin-top: 7px;

  &.icon {
    svg {
      transition: transform $see-more-flip-duration ease $see-more-animation-duration;

      .expanded & {
        transform: rotateX(180deg);
      }
    }
  }
}

.label {
  font-size: 20px;
  font-weight: 200;
}

.measurable {
  bottom: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 1px;
}

.root {
  display: block;
}
