@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../styles/settings/variables';
@import '../../styles/tools/mixins';
@import '../../ingredients/styles/spacing';

.recommendedCarousel {
  position: relative;
  margin-bottom: 8px; 
}

.removePadding{
  padding:0;
}

.removeMarginInMobile {
  @media (max-width: 375px) {
    width: calc(100% + 32px);
    margin-left: -16px;

    .recommendedCarousel{
      padding:0; 
    }
  }
}