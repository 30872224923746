@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';

.wrapper {
  background-color: $colour-white;
  padding: 36px 32px 24px 0;

  &.mobile {
    padding: 24px 0;
  }
  
  .orderNumber {
    p {
      font-size: 28px;
      color: $colour-seasonal-figgy-pudding;
    }

    &.mobile {
      display: flex;
      align-items: center;

      p {
        font-size: 20px;
      }

    }
  }

  .dateAndCTA {
    width: 100%;
    padding-left: 4px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    &.mobile {
      padding-left: 0;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.white {
  background-color: $colour-white;
}