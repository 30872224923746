@import '~@johnlewispartnership/wtr-ingredients/foundations/colours';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/_spacing.scss';
@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';

.legalNotice{
  background-color: $colour-eggshell-grey;
  padding-top: $ingredients-unit * 4;
  padding-bottom: $ingredients-unit * 4;

  @include media-breakpoint-up('sm') {
    padding-top: $ingredients-unit * 6;
    padding-bottom: $ingredients-unit * 6;  
  }

  p {
    margin-bottom: 0;
  }

  a {
    text-decoration: underline;
  }
}
