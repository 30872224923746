@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../styles/tools/mixins';
@import '../../../styles/settings/variables';

.panel {
  $vertical-padding: $grid-vertical-gutter * 5;
  margin: 0 auto;
  min-height: $product-grid-minimum-height;
  padding: $vertical-padding 8px;
  font-family: Waitrose;
  text-align: center;
}

.problem {
  margin: 0;
  font: {
    weight: 400;
    size: $font-size-large;
  }

  @include media-breakpoint-up('md') {
    font-size: $font-size-h2;
  }
}

.reason {
  $top-margin: $grid-vertical-gutter * .5;
  margin: $top-margin 0 0;
  font: {
    weight: 200;
    size: $font-size-base;
  }

  @include media-breakpoint-up('md') {
    font-size: $font-size-large;
  }
}

.children {
  $top-margin: $grid-vertical-gutter * 1.125;
  margin: $top-margin 0 0;
  font: {
    weight: 400;
    size: $font-size-base;
  }

  a {
    color: $text-color;
    text-decoration: underline;
  }

  @include media-breakpoint-up('md') {
    font-size: $font-size-large;
  }
}

.dim {
  color: $colour-chia-grey;
}
