@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/_spacing.scss';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../../styles/settings/variables';
@import '../../../../styles/tools/mixins';
@import '../../mixins/typography';

$image-size: $ingredients-unit * 20;

.productPod {
  @include clearfix;
  border-top: 1px solid $colour-mushroom-grey;
  clear: both;
  display: flex;
  padding: ($ingredients-gutter * .5) 0;

  @include media-breakpoint-up('sm') {
    display: block;
  }

  img {
    float: left;
    height: $image-size;
    margin: 0 ($ingredients-gutter * .5);
    width: $image-size;

    @include media-breakpoint-up('sm') {
      flex: $image-size 0 0;
    }
  }
}

.details {
  display: flex;
  flex: {
    direction: column;
  }
  justify-content: space-between;

  @include media-breakpoint-up('sm') {
    flex: {
      direction: row;
    }
  }
}

.name {
  @include paragraph-sub('medium');

  @include media-breakpoint-up('sm') {
    flex-grow: 1;
  }
}

.quantity {
  @include paragraph-sub('medium');
  flex-grow: 1;

  @include media-breakpoint-up('sm') {
    flex: $image-size 0 0;
  }
}

.price {
  @include title-modal;
  display: block;

  @include media-breakpoint-up('sm') {
    flex: 0 0 110px;
  }
}

.priceYouPay {
  @include paragraph('bold');
  margin-right: $ingredients-unit * 2;

  @include media-breakpoint-up('sm') {
    display: block;
    margin-right: 0;
  }
}

.priceQualifier {
  @include paragraph-sub;

  @include media-breakpoint-up('sm') {
    clear: left;
    display: block;
  }
}
