@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/variables';
@import  '~@johnlewispartnership/wtr-ingredients/dist/styles/tools/mixins';

.categoryCardLink {
  width: 120px;
  display: flex;   
  flex-direction: column;
  align-items: baseline;
  text-decoration: none;
  margin-bottom: -10px;

  @include media-breakpoint-up('md') {
    width: 160px;
    min-height: 193px;
  }
}

.imageWrapper {
  margin-bottom: 4px;
}

.textLinksHeader {
  padding: 0 10px;
}

.linksWrapper {
  list-style-type: none;
  margin: 0 -8px 24px;
}

.link {
  margin-bottom: 0;
}

.offerTextLink {
  color: $colour-offer-red;
}

.imageStyles {
  object-fit: cover;
}